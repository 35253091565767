<template>
  <div class="col-12 col-lg-9 d-lg-none">
             <div class="d-inline-block p-2" style='max-width:20%;float:left;padding: 11px;'>
            <img class="" style='max-width:100%;'  src='/img/hbscmoney.png' alt='icon'>
          </div>
          <div class="d-inline-block" style='max-width:80%;padding-top:5px;'>
            <span class="black-new">HBSC</span>
            <span class="orange-new"> Bonus</span>
            <span class="orange-new"> for</span>
            <span class="black-new"> hxy.business </span>
            <span class="orange-new">investors (1 of 4)</span>
          </div>
          <div class="mt-5" >Claim your hxy business investors' HBSC bonus.</div>
          </div>
</template>

<script>
export default {

}
</script>

<style>

</style>